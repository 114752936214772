/* eslint-disable */
import 	{ Vue, Options } from 'vue-class-component';
import {State, Action, Mutation, Getter} from 'vuex-class';
import {CustomerState} from '@/store/customer/types';
import {getSessionStorageService} from '@/services/storage.service';
import {ValidEmail, ValidPassword, ValidNickName} from '@/services/validation.service';
import {FormData} from './login.interface';
import ActivationPromoCodePage from '@/components/pages/activation-promo-code/activation-promo-code.page'

const namespace: string = 'customer';

 export default class LoginPage extends Vue {
	credentials: FormData = {
		login: '',
		password: ''
	};
	rememberPassword: boolean = false;
	switchWatchPass: boolean = false;
	typeInput: string = 'password';
	isValid: string = '';
	errorName: string = '';

	@State('customer') customer: CustomerState | undefined;
	@Action('login', {namespace}) login: any;
	@Action('fetchUserData', {namespace}) fetchUserData: any;
	@Mutation('setRememberPassword', {namespace}) setRememberPassword: any;
	@Getter('getLanguage', {namespace}) getLanguage: string | undefined;
	@Getter('getRequestStatus', {namespace}) getRequestStatus: any;
	@Getter('getMessages', {namespace}) getMessages: any;

	toSwitchWatchPassword() {
		this.switchWatchPass = !this.switchWatchPass;
		if(!this.switchWatchPass) {
			return this.typeInput = 'password';
		} else {
			return this.typeInput = 'text';
		}
	}

	onSubmit() {
		if (ValidNickName(this.credentials.login) && ValidPassword(this.credentials.password)) {
			this.getRequestStatus ? this.isValid = 'is-valid': '';
			this.login({
				login: this.credentials.login,
				password: this.credentials.password,
			});
			this.setRememberPassword(this.rememberPassword);
		} else {
			console.log(this.getRequestStatus);
			!this.getRequestStatus ? this.isValid = 'is-invalid' : '';
			!ValidNickName(this.credentials.login) ? this.errorName = 'nickname':
				!ValidPassword(this.credentials.password) ? this.errorName = 'pass': '';
		}
	}
}

